
import axios from 'axios';
export default {
    
    async createCustomerUsers(params)  {
        return await axios.post(`customer_users/create` , params)
    },
    async createCustomerUsersList(params)  {
        return await axios.post(`customer_users/create/list` , params)
    },
    async updateCustomerUsersColumn(column , value , data)  {
        return await axios.put(`customer_users/update_list?${column}=${value}` , data)
    },
    async deleteCustomerUsersList(list)  {
        return await axios.delete(`customer_users/delete_list` , {
            data: {
                list:list
            }
        })
    },
    async reportCustomerUsers(params)  {
        return await axios.post(`customer_users/report`, params)
    },
    async getAllCustomerUsers()  {
        return await axios.get(`customer_users/all`)
    },
    async getOneCustomerUsers(customer_user_id)  {
        return await axios.get(`customer_users/all/${customer_user_id}`)
    },
    async getCustomerUsersByColumn(column , value)  {
        return await axios.get(`customer_users/filter?column=${column}&value=${value}`)
    },
    async deleteCustomerUsers(customer_user_id)  {
        return await axios.delete(`customer_users/delete/${customer_user_id}`)
    },
    async updateCustomerUsers(customer_user_id , params)  {
        return await axios.put(`customer_users/update/${customer_user_id}` , params)
    }
}